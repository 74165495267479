<template>
  <RealisasiKpiKorporatForm mode="Tambah" module="Realisasi Indikator Kinerja"> </RealisasiKpiKorporatForm>
</template>

<script>
import RealisasiKpiKorporatForm from './form-realisasi';

const RealisasiKpiKorporatAdd = {
  name: 'RealisasiKpiKorporatAdd',
  components: { RealisasiKpiKorporatForm },
};

export default RealisasiKpiKorporatAdd;
</script>
